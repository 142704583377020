import axios from "axios";
import qs from "qs";
import { useState } from "react";

const onClickLogin = async (email, password) => {
  await axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/auth/jwt/login`,
      qs.stringify({
        username: email,
        password: password,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
    .then((res) => {
      const serverToken = res.data;
      localStorage.setItem("bitmnky_auth", JSON.stringify(serverToken));
    })
    .catch((err) => {
      alert("로그인에 실패했습니다.");
      return;
    });

  window.location.reload(false);
};

const onClickRegister = async (email, password, passwordConfirm) => {
  if (password !== passwordConfirm) {
    alert("비밀번호가 일치하지 않습니다.");
    return;
  }
  axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/auth/register`,
      {
        email: email,
        password: password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      alert("회원가입에 성공했습니다. 로그인해주세요.");
      window.location.reload(false);
    })
    .catch((err) => {
      alert("회원가입에 실패했습니다.");
      return;
    });
};

const LoginSection = () => {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const [registrationEmail, setRegistrationEmail] = useState("");
  const [registrationPassword, setRegistrationPassword] = useState("");
  const [registrationPasswordConfirm, setRegistrationPasswordConfirm] =
    useState("");
  return (
    <>
      <h3>로그인</h3>
      <span>email</span>
      <input type="email" onChange={(e) => setLoginEmail(e.target.value)} />
      <span>password</span>
      <input
        type="password"
        onChange={(e) => setLoginPassword(e.target.value)}
      />
      <br />
      <button onClick={() => onClickLogin(loginEmail, loginPassword)}>
        로그인
      </button>
      <h4>또는, 회원가입</h4>
      <span>email</span>
      <input
        type="email"
        onChange={(e) => setRegistrationEmail(e.target.value)}
      />
      <span>password</span>
      <input
        type="password"
        onChange={(e) => setRegistrationPassword(e.target.value)}
      />
      <span>password 확인</span>
      <input
        type="password"
        onChange={(e) => setRegistrationPasswordConfirm(e.target.value)}
      />
      <br />
      <button
        onClick={() =>
          onClickRegister(
            registrationEmail,
            registrationPassword,
            registrationPasswordConfirm
          )
        }
      >
        회원가입
      </button>
    </>
  );
};

export default LoginSection;
