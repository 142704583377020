import "./App.css";
import LoginSection from "./components/loginSection";
import { useLocalStorage, deleteStorageValue } from "./hooks/localStorage";
import { Dashboard } from "./components/dashboard";

function onClickLogout() {
  deleteStorageValue("bitmnky_auth");
  window.location.reload(false);
}

function App() {
  const authInfo = useLocalStorage("bitmnky_auth", null)[0];
  return (
    <div className="App">
      <header className="App-header">
        {authInfo !== null ? (
          <>
            <button onClick={() => onClickLogout()}>로그아웃</button>
            <Dashboard />
          </>
        ) : (
          <LoginSection />
        )}
        <div style={{ marginBottom: "100px" }}></div>
      </header>
    </div>
  );
}

export default App;
