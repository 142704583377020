import { getStorageValue, deleteStorageValue } from "../hooks/localStorage";
import axios from "axios";
import { useEffect } from "react";
import { create } from "zustand";

const useStore = create((set) => ({
  myInfo: null,
  performanceInfo: null,
  upbitAccessKey: null,
  upbitSecretKey: null,
  upbitCertified: false,
  intervalBuyCost: null,
  intervalMinutes: null,
  limitSellProfitPercentage: null,
  maxAccountCount: null,
  setMyInfo: (info) => set({ myInfo: info }),
  setPerformanceInfo: (info) => set({ performanceInfo: info }),
  setUpbitAccessKey: (key) => set({ upbitAccessKey: key }),
  setUpbitSecretKey: (key) => set({ upbitSecretKey: key }),
  setUpbitCertified: (certified) => set({ upbitCertified: certified }),
  setIntervalBuyCost: (cost) => set({ intervalBuyCost: cost }),
  setIntervalMinutes: (minutes) => set({ intervalMinutes: minutes }),
  setLimitSellProfitPercentage: (percentage) =>
    set({ limitSellProfitPercentage: percentage }),
  setMaxAccountCount: (count) => set({ maxAccountCount: count }),
  fetchUserInfo: () => {
    const authInfo = getStorageValue("bitmnky_auth");
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/users/me`, {
        headers: {
          Authorization: `Bearer ${authInfo.access_token}`,
        },
      })
      .then((res) => {
        set({
          myInfo: res.data,
          upbitAccessKey: res.data.upbit_config.access_key,
          upbitSecretKey: res.data.upbit_config.secret_key,
          intervalBuyCost:
            res.data.function_setting_field.default.interval_buy_cost,
          intervalMinutes:
            res.data.function_setting_field.default.interval_minutes,
          limitSellProfitPercentage:
            res.data.function_setting_field.default
              .limit_sell_profit_percentage,
          maxAccountCount:
            res.data.function_setting_field.default.max_account_count,
        });
        if (
          res.data.upbit_config.access_key !== null &&
          res.data.upbit_config.secret_key !== null
        ) {
          set({ upbitCertified: true });
        }
      })
      .catch((err) => {
        deleteStorageValue("bitmnky_auth");
        window.location.reload(false);
      });
  },
  fetchActionInfo: () => {
    const toDate = new Date();
    const fromDate = new Date(toDate);
    fromDate.setDate(toDate.getDate() - 30);
    const authInfo = getStorageValue("bitmnky_auth");

    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/actions/report`, {
        params: {
          from_date: fromDate.toISOString(),
          end_date: toDate.toISOString(),
        },
        headers: {
          Authorization: `Bearer ${authInfo.access_token}`,
        },
      })
      .then((res) => {
        set({ performanceInfo: res.data });
      })
      .catch((err) => {
        alert("일시적인 오류가 발생했습니다. 새로고침을 해주세요.");
      });
  },
  checkAndSaveUpbitKeys: (accessKey, secretKey) => {
    if (accessKey === null || secretKey === null) {
      alert("access key와 secret key를 입력해주세요.");
      return;
    }
    const authInfo = getStorageValue("bitmnky_auth");
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/users/register/upbit_certification`,
        {
          access_key: accessKey,
          secret_key: secretKey,
        },
        {
          headers: {
            Authorization: `Bearer ${authInfo.access_token}`,
          },
        }
      )
      .then((res) => {
        alert("인증되었습니다.");
        set({ upbitCertified: true });
        window.location.reload(false);
      })
      .catch((err) => {
        alert("연동에 실패했습니다.");
      });
  },
}));

function registerActionInfo(
  interval_buy_cost,
  interval_minutes,
  limit_sell_profit_percentage,
  max_account_count
) {
  const authInfo = getStorageValue("bitmnky_auth");
  if (interval_buy_cost < 5500) {
    alert("interval_buy_cost는 5500 이상이어야 합니다.");
    return;
  }

  if (interval_minutes >= 10 && interval_minutes % 10 !== 0) {
    alert("interval_minutes는 10의 배수여야 합니다.");
    return;
  }

  if (limit_sell_profit_percentage < 0.5) {
    alert("limit_sell_profit_percentage는 0.5 이상이어야 합니다.");
    return;
  }

  if (max_account_count < 1) {
    alert("max_account_count는 1 이상이어야 합니다.");
    return;
  }

  axios
    .patch(
      `${process.env.REACT_APP_SERVER_URL}/users/me`,
      {
        function_setting_field: {
          default: {
            interval_buy_cost: interval_buy_cost,
            interval_minutes: interval_minutes,
            limit_sell_profit_percentage: limit_sell_profit_percentage,
            max_account_count: max_account_count,
          },
        },
      },
      {
        headers: {
          Authorization: `Bearer ${authInfo.access_token}`,
        },
      }
    )
    .then((res) => {
      alert("설정 정보가 저장되었습니다. 자동매수를 실행합니다.");
      window.location.reload(false);
    })
    .catch((err) => {
      alert("설정 정보 저장에 실패했습니다.");
    });
}

function resetActionInfo() {
  const authInfo = getStorageValue("bitmnky_auth");
  axios
    .patch(
      `${process.env.REACT_APP_SERVER_URL}/users/me`,
      {
        function_setting_field: {
          default: {},
        },
      },
      {
        headers: {
          Authorization: `Bearer ${authInfo.access_token}`,
        },
      }
    )
    .then((res) => {
      alert("자동매수가 중지되었습니다");
      window.location.reload(false);
    })
    .catch((err) => {
      alert("초기화에 실패했습니다.");
    });
}

export function Dashboard() {
  const {
    myInfo,
    performanceInfo,
    upbitAccessKey,
    setUpbitAccessKey,
    upbitSecretKey,
    setUpbitSecretKey,
    upbitCertified,
    intervalBuyCost,
    setIntervalBuyCost,
    intervalMinutes,
    setIntervalMinutes,
    limitSellProfitPercentage,
    setLimitSellProfitPercentage,
    maxAccountCount,
    setMaxAccountCount,
    fetchUserInfo,
    fetchActionInfo,
    checkAndSaveUpbitKeys,
  } = useStore((state) => ({
    ...state,
  }));

  useEffect(() => {
    fetchUserInfo();
  }, [fetchUserInfo]);

  useEffect(() => {
    if (upbitCertified === false) return;
    fetchActionInfo();
  }, [fetchActionInfo, upbitCertified]);

  return (
    <div>
      <p>안녕하세요. {myInfo?.email}</p>
      <a
        href="https://sincere-frill-729.notion.site/8efa267848964941967ee4c07a9cdbdf?pvs=4"
        rel="noreferrer"
        target="_blank"
      >
        비트몽키 사용 가이드
      </a>
      <div>
        <h3>업비트 인증하기</h3>
        <span>ACCESS KEY: </span>
        <input
          type="text"
          onChange={(e) => setUpbitAccessKey(e.target.value)}
          value={upbitAccessKey}
        />
        <br />
        <span>SECRET KEY: </span>
        <input
          type="text"
          onChange={(e) => setUpbitSecretKey(e.target.value)}
          value={upbitSecretKey}
        />
        <br />
        <button
          onClick={() => checkAndSaveUpbitKeys(upbitAccessKey, upbitSecretKey)}
        >
          인증하기
        </button>
      </div>
      <div>
        <h3>액션 정보 설정하기</h3>
        <span>매 회차 적립식 투자 금액 : </span>
        <input
          type="number"
          onChange={(e) => setIntervalBuyCost(e.target.value)}
          value={intervalBuyCost}
        />
        원
        <br />
        <span>적립식 투자 간격 (10배수로 입력): </span>
        <input
          type="number"
          onChange={(e) => setIntervalMinutes(e.target.value)}
          value={intervalMinutes}
        />
        분
        <br />
        <span>목표 수익률 (정수로 입력): </span>
        <input
          type="number"
          onChange={(e) => setLimitSellProfitPercentage(e.target.value)}
          value={limitSellProfitPercentage}
        />
        %
        <br />
        <span>최대 보유 코인 종류갯수: </span>
        <input
          type="number"
          onChange={(e) => setMaxAccountCount(e.target.value)}
          value={maxAccountCount}
        />
        개
        <br />
        <button
          onClick={() =>
            registerActionInfo(
              intervalBuyCost,
              intervalMinutes,
              limitSellProfitPercentage,
              maxAccountCount
            )
          }
        >
          시작/수정하기
        </button>
        <br />
        <button onClick={() => resetActionInfo()}>중지하기</button>
      </div>
      <div>
        {performanceInfo === null ? (
          <p>로딩중...</p>
        ) : (
          <>
            <div>
              <h4> 내 계좌 상태 확인하기</h4>
              <table>
                <thead>
                  <tr>
                    <th>종목</th>
                    <th>현재가</th>
                    <th>평단가</th>
                    <th>투자금액</th>
                    <th>평가손익</th>
                    <th>수익률</th>
                  </tr>
                </thead>
                <tbody>
                  {performanceInfo?.profit_status_list?.map((profit_status) => (
                    <tr>
                      <td>{profit_status.current_price.market}</td>
                      <td>
                        {profit_status.current_price.trade_price.toLocaleString()}
                        원
                      </td>
                      <td>
                        {profit_status.account.avg_buy_price.toLocaleString()}원
                      </td>
                      <td>
                        {Math.floor(
                          profit_status.total_invested_amount
                        ).toLocaleString()}
                        원
                      </td>
                      <td>
                        {Math.floor(
                          profit_status.total_profit_amount
                        ).toLocaleString()}
                        원
                      </td>
                      <td>{profit_status.profit_ratio.toFixed(2)}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <>
              <h4>투자 성과 확인하기 (최근 30일)</h4>
              <p>
                실현 수익:{" "}
                <span style={{ fontSize: "30px" }}>
                  {Math.floor(
                    performanceInfo?.total_confirmed_profit
                  ).toLocaleString()}
                </span>
                원
              </p>

              <p>
                총 매수 금액:{" "}
                <span>
                  {Math.floor(
                    performanceInfo?.total_buy_amount
                  ).toLocaleString()}
                </span>
                원
              </p>
              <p>
                총 매도 금액:{" "}
                {Math.floor(
                  performanceInfo?.total_sell_amount
                ).toLocaleString()}
                원
              </p>

              <p>매도 이력</p>
              <table>
                <thead>
                  <tr>
                    <th>마켓</th>
                    <th>매도 가격</th>
                    <th>매수 평균 가격</th>
                    <th>매도량</th>
                    <th>매도금액</th>
                    <th>실현 수익</th>
                    <th>주문 날짜</th>
                  </tr>
                </thead>
                <tbody>
                  {performanceInfo?.total_sell_transactions
                    ?.sort(
                      (a, b) => new Date(b.created_at) - new Date(a.created_at)
                    )
                    .map((sell) => (
                      <tr>
                        <td>{sell.market}</td>
                        <td>{sell.price.toLocaleString()}원</td>
                        <td>{sell.avg_buy_price.toFixed(2)}원</td>
                        <td>{sell.volume.toFixed(2)}개</td>
                        <td>
                          {Math.floor(
                            sell.price * sell.volume
                          ).toLocaleString()}
                          원
                        </td>
                        <td>
                          {Math.floor(
                            (sell.price - sell.avg_buy_price) * sell.volume
                          )}
                          원
                        </td>
                        <td>
                          {new Date(sell.created_at).toLocaleDateString()}{" "}
                          {new Date(sell.created_at).toLocaleTimeString()}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          </>
        )}
      </div>
    </div>
  );
}
